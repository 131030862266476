import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const rawTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#a91c22',
      light: '#e1534a',
      dark: '#730000'
    },
    secondary: {
      main: '#50504f',
      light: '#C6C6C6',
      dark: '#282827'
    },
    background: {
      default: '#fdfdfd'
    },
    cream: {
      main: '#EEEAE3'
    },
    white: {
      main: '#fff',
      dark: '#c6c6c6'
    },
    dark: {
      main: '#414042'
    },
    dividerGray: {
      main: '#c6c6c6'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  typography: {
    fontFamily: '"Inter",Helvetica,Arial,Lucida,sans-serif;',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontFamilySecondary: '"EB Garamond",Helvetica,Arial,Lucida,sans-serif;',
    fontColorPrimary: '#414042'
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    }
  }
});

const fontHeader = {
  color: rawTheme.typography.fontColorPrimary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamily
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.background.default,
      placeholder: grey[200]
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      fontSize: '3 rem',
      lineHeight: '3 rem',
      fontWeight: rawTheme.typography.fontWeightLight,
      fontFamily: rawTheme.typography.fontFamily,
      color: rawTheme.typography.fontColorPrimary
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: '2 rem',
      lineHeight: '2 rem',
      fontWeight: rawTheme.typography.fontWeightBold,
      fontFamily: rawTheme.typography.fontFamily,
      color: rawTheme.typography.fontColorPrimary,
      textTransform: 'uppercase'
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: '2 rem',
      lineHeight: '2.1 rem',
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontFamily: rawTheme.typography.fontFamilySecondary,
      color: rawTheme.typography.fontColorPrimary,
      textTransform: 'italic'
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: '1.5 rem',
      lineHeight: '1.6 rem',
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontFamily: rawTheme.typography.fontFamily,
      color: rawTheme.typography.fontColorPrimary
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: '1.5 rem',
      lineHeight: '1.7 rem',
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontFamily: rawTheme.typography.fontFamilySecondary,
      color: rawTheme.typography.fontColorPrimary
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: '1.1 rem',
      lineHeight: '1.2 rem',
      fontWeight: rawTheme.typography.fontWeightMedium,
      fontFamily: rawTheme.typography.fontFamily,
      color: rawTheme.typography.fontColorPrimary
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1
      /* fontFamily: rawTheme.typography.fontFamilySecondary,
      fontSize: "1rem",
      letterSpacing: 2,
      lineHeight: 1.6,
      color: rawTheme.palette.white.main,
      fontWeight: 400,
      [rawTheme.breakpoints.down("md")]: {
        fontSize: 14,
        letterSpacing: 1,
      }, */
    },
    subtitle2: {
      ...rawTheme.typography.body1
      /* fontSize: 15,
      textTransform: "uppercase",
      letterSpacing: 2, */
    },
    subtitle3: {
      ...rawTheme.typography.body1
      /* fontFamily: rawTheme.typography.fontFamilySecondary,
      fontSize: 15,
      textTransform: "uppercase",
      letterSpacing: 1, */
    },
    body1: {
      ...rawTheme.typography.body2,
      fontSize: '1 rem',
      lineHeight: '1.65 rem',
      fontWeight: rawTheme.typography.fontWeightLight,
      fontFamily: rawTheme.typography.fontFamily,
      color: rawTheme.typography.fontColorPrimary
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    }
  }
};

export default theme;
