import makeStore from './makeStore';

const initialState = {
  displayCards: false,
  displayCardsManual: false,
  printLayout: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDisplayCards':
      return {
        ...state,
        displayCards: action.value,
        displayCardsManual: action.manual
      };
    case 'setPrintLayout':
      return {
        ...state,
        printLayout: action.value
      };
    default:
      throw new Error('Unknown action!' /*  + action */);
  }
};

const [UIProvider, useUIDispatch, useUIStore] = makeStore(reducer, initialState, 'uiStore');

export { UIProvider, useUIDispatch, useUIStore };
